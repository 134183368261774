<template>
    <div class="weather-card">
        <div id="he-plugin-standard"></div>
    </div>
</template>
<script>
export default {
    name: 'WeatherCard',
    components: {},
    data() {
        return {}
    },
    created() {
        //和风天气插件调用
        window.WIDGET = {
            CONFIG: {
                layout: '1',
                width: '360',
                height: '150',
                background: '1',
                dataColor: 'FFFFFF',
                borderRadius: '30',
                key: '44ee4e5a7bfb46009a41e1c6d5c404c8'
            }
        }
        ;(function(d) {
            var c = d.createElement('link')
            c.rel = 'stylesheet'
            c.href =
                'https://widget.heweather.net/standard/static/css/he-standard.css?v=1.4.0'
            var s = d.createElement('script')
            s.src =
                'https://widget.qweather.net/standard/static/js/he-standard-common.js?v=2.0"'
            var sn = d.getElementsByTagName('script')[0]
            sn.parentNode.insertBefore(c, sn)
            sn.parentNode.insertBefore(s, sn)
        })(document)
    }
}
</script>
<style lang="less" scoped>
.weather-card {
    box-sizing: border-box;
    /deep/ #he-plugin-standard {
        height: 100% !important;
        width: 100% !important;
        // 隐藏地点和商标
        .wv-v-h-refresh,
        .wv-v-h-location {
            // .wv-lt-refresh {
            display: none;
            padding: 0;
        }
        .wv-v-h-row {
          display: flex;
          align-items: center;
        }
        // 当前天气
        .wv-v-h-col-left {
          width: 40%!important;
        }
        .wv-v-h-col-right {
          width: 60%!important;
        }
        .wv-v-h-now {
            .wv-n-h-now-content {
                .wv-n-h-now-alarm {
                    display: none;
                    margin-left: .1rem;
                }
            }
        }
    }
}
</style>
